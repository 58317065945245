import React, {useEffect, useState}from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from "@mui/material/Alert";
import {LoadingButton} from "@mui/lab";
import SendIcon from '@mui/icons-material/Send';
import {useAtom} from "jotai";
import {guestTokenAtom, guestTokenErrorMessageAtom, guestTokenValidatingAtom, guestTokenValidAtom} from "../../atoms/meetingAtom";

export default function RequestScheduleTokenDialog({
  isOpen= false,
}) {
  const [open, setOpen] = useState(isOpen);
  const [token, setToken] = useState('');
  const [guestToken, setGuestToken] = useAtom(guestTokenAtom);
  const [guestTokenValid] = useAtom(guestTokenValidAtom);
  const [guestTokenValidating] = useAtom(guestTokenValidatingAtom);
  const [guestTokenErrorMessage] = useAtom(guestTokenErrorMessageAtom);

  useEffect(() => {
    console.log(`isGuestTokenValid`, guestTokenValid)
    if(guestTokenValid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [guestTokenValid]);

  const handleSetToken = () => {
    setGuestToken(token);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleSetToken}>
        <DialogTitle>Join a Meeting</DialogTitle>
        <DialogContent>
          {guestTokenErrorMessage && <Alert severity="error">{guestTokenErrorMessage}</Alert>}
          <DialogContentText>
            To enter the meeting waiting room, please enter your schedule ID / token here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="schedule-id"
            label="Schedule ID / Token"
            type="text"
            fullWidth
            variant="standard"
            value={token}
            onChange={(event) => setToken(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={handleSetToken}
            endIcon={<SendIcon />}
            loading={guestTokenValidating}
            loadingPosition="end"
            variant="contained"
          >
            <span>Enter Waiting Room</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
