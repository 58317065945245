import React, {useState, useContext, useEffect, createContext, useRef, useCallback} from 'react';

const ylogger = console;

const gotStream = (stream) => {
  // window.stream = stream; // make stream available to console
  // videoElement.srcObject = stream;
  // Refresh button list in case labels have become available
  return navigator.mediaDevices.enumerateDevices();
}

const handleError = (error) => {
  console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
}

const startAudio = () => {
  if (window.stream) {
    window.stream.getTracks().forEach(track => {
      track.stop();
    });
  }

  const constraints = {
    audio: true,
    video: false
  };

  return navigator.mediaDevices.getUserMedia(constraints).then(gotStream);
}

const startVideo = () => {
  if (window.stream) {
    window.stream.getTracks().forEach(track => {
      track.stop();
    });
  }

  const constraints = {
    audio: false,
    video: true
  };

  return navigator.mediaDevices.getUserMedia(constraints).then(gotStream);
}

const gotSources = (sourceInfos) => {
  ylogger.debug('gotSources', sourceInfos);

  let audioIn = [], audioOut = [], video = [];
  // selectSourceDiv.classList.remove('hidden');
  for (let i = 0; i < sourceInfos.length; i++) {
    console.log(sourceInfos[i])
    // const option = document.createElement('option');
    // option.value = sourceInfos[i].deviceId;
    // option.text = sourceInfos[i].label;
    if (sourceInfos[i].kind === 'audioinput') {
      const device = {
        deviceId: sourceInfos[i].deviceId,
        label: sourceInfos[i].label,
      }
      audioIn.push(device);
    } else if (sourceInfos[i].kind === 'audiooutput') {
      const device = {
        deviceId: sourceInfos[i].deviceId,
        label: sourceInfos[i].label,
      }
      audioOut.push(device);
    } else if (sourceInfos[i].kind === 'videoinput') {
      const device = {
        deviceId: sourceInfos[i].deviceId,
        label: sourceInfos[i].label,
      }
      video.push(device);
    } else {
      console.log('unknown', JSON.stringify(sourceInfos[i]));
    }
  }

  return {
    audioIn,
    audioOut,
    video,
  };
}

const getConstraintAudioDevice = (deviceId) => {
  if(deviceId === 'off') {
    return false;
  } else if(deviceId === 'default') {
    return true;
  } else if(deviceId) {
    return {deviceId: {exact: deviceId}};
  } else {
    return false;
  }
}

const getConstraintVideoDevice = (deviceId) => {
  if(deviceId === 'off') {
    return false;
  } else if(deviceId === 'default') {
    return true;
  } else if(deviceId) {
    return {deviceId: {exact: deviceId}};
  } else {
    return false;
  }
}

const DevicesContext = createContext({});

export const useDevices = () => useContext(DevicesContext);

export const DevicesContextProvider = (props) => {
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [videoInputDevices, setVideoInputDevices] = useState([]);
  const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState('');
  const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState('');
  const [selectedVideoInputDevice, setSelectedVideoInputDevice] = useState('');

  const [audioPermissionError, setAudioPermissionError] = useState(false);
  const [videoPermissionError, setVideoPermissionError] = useState(false);

  useEffect(() => {
    startAudio()
    .then(gotSources)
    .then((devices) => {
      setAudioInputDevices(devices.audioIn);
      setAudioOutputDevices(devices.audioOut);
      // setVideoInputDevices(devices.video);

      if(devices.audioIn.length > 0 && selectedAudioInputDevice === '') {
        setSelectedAudioInputDevice(devices.audioIn[0].deviceId);
      }
      if(devices.audioOut.length > 0 && selectedAudioOutputDevice === '') {
        setSelectedAudioOutputDevice(devices.audioOut[0].deviceId);
      }

      setAudioPermissionError(false);
    })
    .catch((error) => {
      ylogger.error('DevicesContextProvider: startAudio error', error);
      setAudioPermissionError(true);
    });

    startVideo()
    .then(gotSources)
    .then((devices) => {
      setVideoInputDevices(devices.video);

      if(devices.video.length > 0 && selectedVideoInputDevice === '') {
        setSelectedVideoInputDevice(devices.video[0].deviceId);
      }

      setVideoPermissionError(false);
    })
    .catch((error) => {
      ylogger.error('DevicesContextProvider: startVideo error', error);
      setVideoPermissionError(true);
    });

  }, []);

  useEffect(() => {
    // const getConnectedDevices = () => {
    //   navigator
    //   .mediaDevices
    //   // .getUserMedia({audio: true, video: true})
    //   .enumerateDevices()
    //   .then(gotSources)
    //   .then((devices) => {
    //     setAudioInputDevices(devices.audioIn);
    //     setAudioOutputDevices(devices.audioOut);
    //     setVideoInputDevices(devices.video);
    //
    //     if(devices.audioIn.length > 0 && selectedAudioInputDevice === '') {
    //       setSelectedAudioInputDevice(devices.audioIn[0].deviceId);
    //     }
    //     if(devices.audioOut.length > 0 && selectedAudioOutputDevice === '') {
    //       setSelectedAudioOutputDevice(devices.audioOut[0].deviceId);
    //     }
    //     if(devices.video.length > 0 && selectedVideoInputDevice === '') {
    //       setSelectedVideoInputDevice(devices.video[0].deviceId);
    //     }
    //   });
    // }
    //
    // getConnectedDevices();
    //
    // // Listen for changes to media devices and update the list accordingly
    // navigator.mediaDevices.addEventListener('devicechange', event => {
    //   ylogger.debug('DevicesContextProvider: devicechange', event);
    //   getConnectedDevices();
    // });
  }, []);

  const getConstraints = useCallback(() => {
    return {
      audio: getConstraintAudioDevice(selectedAudioInputDevice),
      video: getConstraintVideoDevice(selectedVideoInputDevice),
    }
  }, [selectedAudioInputDevice, selectedVideoInputDevice]);

  return (
    <DevicesContext.Provider value={{
      audioInputDevices,
      audioOutputDevices,
      videoInputDevices,
      selectedAudioInputDevice,
      selectedAudioOutputDevice,
      selectedVideoInputDevice,
      setSelectedAudioInputDevice,
      setSelectedAudioOutputDevice,
      setSelectedVideoInputDevice,
      getConstraints,
      audioPermissionError,
      videoPermissionError,
    }}>
      {props.children}
    </DevicesContext.Provider>
  );

};
