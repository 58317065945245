import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {Fab, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import {useDevices} from "../../contexts/DevicesContext";
import {useVideoCall} from "../../contexts/VideoCallContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ControlFabButton = styled(Fab, {
  shouldForwardProp: (prop) =>
    !["red"].includes(prop)
})(({red}) => ({
  backdropFilter: 'blur(6px)',
  backgroundColor: !red ? 'rgba(100,100,100, 0.4)' : '#EA6861',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: !red ? 'rgba(100,100,100, 1)' : '#ee5c4b',
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SettingDialog(props) {
  const [open, setOpen] = React.useState(false);
  const {
    getRemoteAudioElement,
    changedInputDevices,
    doCall,
  } = useVideoCall();
  const {
    audioInputDevices,
    audioOutputDevices,
    videoInputDevices,
    selectedAudioInputDevice,
    selectedAudioOutputDevice,
    selectedVideoInputDevice,
    setSelectedAudioInputDevice,
    setSelectedAudioOutputDevice,
    setSelectedVideoInputDevice,
  } = useDevices();

  console.log({
    audioInputDevices,
    audioOutputDevices,
    videoInputDevices,
    selectedAudioInputDevice,
    selectedAudioOutputDevice,
    selectedVideoInputDevice,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if(reason !== 'backdropClick') {
      changedInputDevices();
      setOpen(false);
    }
  };

  const handleChangeAudioInput = (event) => {
    setSelectedAudioInputDevice(event.target.value);

  };

  const handleChangeAudioOutput = (event) => {
    setSelectedAudioOutputDevice(event.target.value);
    getRemoteAudioElement().setSinkId(event.target.value);
  };

  const handleChangeVideoInput = (event) => {
    setSelectedVideoInputDevice(event.target.value);
  }

  return (
    <>
      <ControlFabButton onClick={handleClickOpen}>
        <SettingsRoundedIcon />
      </ControlFabButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableEscapeKeyDown={true}
        maxWidth={'md'}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Settings
        </BootstrapDialogTitle>
        <DialogContent>
          <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
            <InputLabel id="settings-audio-input">Microphone</InputLabel>
            <Select
              labelId="settings-audio-input"
              id="settings-audio-input"
              value={selectedAudioInputDevice}
              label="Microphone"
              onChange={handleChangeAudioInput}
            >
              {audioInputDevices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
            <InputLabel id="settings-audio-output">Speaker</InputLabel>
            <Select
              labelId="settings-audio-output"
              id="settings-audio-output"
              value={selectedAudioOutputDevice}
              label="Speaker"
              onChange={handleChangeAudioOutput}
            >
              {audioOutputDevices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
            <InputLabel id="settings-video-input">Camera</InputLabel>
            <Select
              labelId="settings-video-input"
              id="settings-video-input"
              value={selectedVideoInputDevice}
              label="Camera"
              onChange={handleChangeVideoInput}
            >
              {videoInputDevices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

        </DialogContent>
        <DialogActions>
          {/*<Button autoFocus onClick={handleClose}>*/}
          {/*  Save changes*/}
          {/*</Button>*/}
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
