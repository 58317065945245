import React, {useEffect} from 'react';
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useStopwatch } from 'react-timer-hook';
import {useVideoCall} from "../../contexts/VideoCallContext";

const StyledVideoTimer = styled(Box)({
  border: 'none',
  height: "40px",
  width: "130px",
  backdropFilter: 'blur(6px)',
  padding: '6px',
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  color: '#ffffff',
  borderRadius: "20px",
  fontSize: "18px",
  fontFamily: "'Poppins', sans-serif",
  fontWeight: "500",
});

const VideoTimer = () => {
  const {
    seconds,
    minutes,
    hours,
    start,
    pause,
    resume,
    reset,
  } = useStopwatch({autoStart: false});

  const {
    callState,
  } = useVideoCall();

  useEffect(() => {
    console.log(callState.inCall)
    if(callState.inCall) {
      reset();
    } else{
      pause();
    }
  }, [callState.inCall]);

  const timerHour = hours === 0 ? '' : (hours < 10 ? `0${hours}:` : hours);
  const timerMinute = minutes < 10 ? `0${minutes}` : minutes;
  const timerSecond = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <StyledVideoTimer>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <div>{`${timerHour}${timerMinute}:${timerSecond}`}</div>
      </Stack>
    </StyledVideoTimer>
  );
};

export default VideoTimer;
