import React from 'react';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {Fab} from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';

const VideoButton = styled(Fab, {
  shouldForwardProp: (prop) =>
    !["active", "sx"].includes(prop)
})(({ theme, active, sx }) => ({
  height: '60px',
  width: '60px',
  backgroundColor: active ? '#e4f3f1' : 'transparent',
  boxShadow: 'none',
  borderRadius: '20%',
  color: active ? '#28c9b0' : '#696666',
  '&:hover': {
    // backgroundColor: '#ee5c4b',
  }
}));

const LeftSidebar = (props) => {

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      flex={1}
    >
      <Box
        sx={{
          height: '160px',
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            paddingTop: '20px'
          }}
        >
          <img src="https://www.ytel.com/hubfs/ytel_logo.png"
               alt="ytel_logo"
               width="50%"
          />
        </Stack>
      </Box>

      <Box
        flex={1}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={5}
          sx={{
            paddingTop: '20px',
            height: '100%',
          }}
        >
          <VideoButton disableRipple={true} active>
            <VideocamIcon />
          </VideoButton>

          <VideoButton>
            <CalendarMonthIcon />
          </VideoButton>

          <VideoButton>
            <PeopleAltIcon />
          </VideoButton>

          {/*<VideoButton>*/}
          {/*  <LeaderboardRoundedIcon />*/}
          {/*</VideoButton>*/}
        </Stack>
      </Box>
    </Box>
  );
}

export default LeftSidebar;
