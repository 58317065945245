import * as React from 'react';
import MainContainer from "./components/MainContainer";
import {VideoCallContextProvider} from "./contexts/VideoCallContext";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DevicesContextProvider} from "./contexts/DevicesContext";
import RequestScheduleTokenDialog from "./components/Dialogs/RequestScheduleTokenDialog";
import AlertDialog from "./components/Dialogs/AlertDialog";
import {useAtom} from "jotai";
import {guestTokenAtom, guestTokenValidatingAtom, guestTokenValidAtom} from "./atoms/meetingAtom";
import {useEffect} from "react";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const scheduleId = urlParams.get('schedule') ?? null;
  const guestTokenInput = urlParams.get('token') ?? scheduleId;

  const [guestToken, setGuestToken] = useAtom(guestTokenAtom);
  const [guestTokenValid] = useAtom(guestTokenValidAtom);

  useEffect(() => {
    if(guestTokenInput) {
      setGuestToken(guestTokenInput);
    }
  }, [guestTokenInput]);

  return (
    <ThemeProvider theme={theme}>
      {/*<AlertDialog />*/}
      <RequestScheduleTokenDialog />
      {guestTokenValid &&
        <DevicesContextProvider>
          <VideoCallContextProvider roomToken={guestToken}>
            <MainContainer />
          </VideoCallContextProvider>
        </DevicesContextProvider>
      }
    </ThemeProvider>
  );
}
