import React from 'react';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useDevices} from "../../contexts/DevicesContext";

const DeviceAudioInDropdown = ({handleChange}) => {
  const {
    audioInputDevices,
    selectedAudioInputDevice,
  } = useDevices();

  console.log('selectedAudioInputDevice', selectedAudioInputDevice)

  return (
    <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
      <InputLabel id="settings-audio-input" shrink={true}>Microphone</InputLabel>
      <Select
        labelId="settings-audio-input"
        id="settings-audio-input"
        value={selectedAudioInputDevice}
        label="Microphone"
        notched={true}
        onChange={handleChange}
      >
        <MenuItem key="off" value="off">Muted</MenuItem>
        {audioInputDevices.map((device) => (
          <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DeviceAudioInDropdown;
