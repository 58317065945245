import React, {useEffect, useRef} from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useDevices} from "../../contexts/DevicesContext";
import {useVideoCall} from "../../contexts/VideoCallContext";
import PendingVideo from "./PendingVideo";
import DeviceAudioInDropdown from "../Settings/DeviceAudioInDropdown";
import SoundMeter from "./SoundMeter";
// import "../../helper/soundmeter";

const PendingConnectionBox = (props) => {
  const pendingVideoRef = useRef(null);
  // const audioInputMeterRef = useRef(null);
  const [webrtcStream, setWebrtcStream] = React.useState(null);
  const {
    audioInputDevices,
    audioOutputDevices,
    videoInputDevices,
    selectedAudioInputDevice,
    selectedAudioOutputDevice,
    selectedVideoInputDevice,
    setSelectedAudioInputDevice,
    setSelectedAudioOutputDevice,
    setSelectedVideoInputDevice,
    getConstraints,
  } = useDevices();
  const {
    doCall,
    // getRemoteAudioElement,
  } = useVideoCall();


  useEffect(() => {
    const constraints = getConstraints();

    let currentStream = null;

    navigator.mediaDevices.getUserMedia(constraints)
    .then((stream) => {
      currentStream = pendingVideoRef.current.srcObject = stream;
      pendingVideoRef.current.volume = 0;

      setWebrtcStream(stream);
    })
    .catch((err) => {
      console.log('error', err)
    });

    return () => {
      if(currentStream) {
        currentStream.getTracks().forEach((track) => {
          console.log('stopping track', track)
          track.stop();
        });

        setWebrtcStream(null);
      }
    };
  }, [selectedAudioInputDevice, selectedVideoInputDevice]);

  useEffect(() => {
    console.log(`Video tab mounted`)

    return () => {
      console.log(`Video tab clean up`)
      if(webrtcStream) {
        webrtcStream.getTracks().forEach((track) => {
          console.log('stopping track', track)
          track.stop();
        });
      }
    };
  }, [webrtcStream]);

  const handleChangeAudioInput = (event) => {
    setSelectedAudioInputDevice(event.target.value);
  };

  const handleChangeAudioOutput = (event) => {
    setSelectedAudioOutputDevice(event.target.value);
    pendingVideoRef.current.setSinkId(event.target.value);
  };

  const handleChangeVideoInput = (event) => {
    setSelectedVideoInputDevice(event.target.value);
  }


  return (
    <Box sx={{
      position: 'relative',
      padding: "30px",
      // backgroundColor: "red",
    }}>
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          height: '80vh',
          borderRadius: '30px',
          // backgroundColor: '#646464',
          border: '3px solid #e0e0e0',
        }}
      >
        <Box
          flex={1}
          display="flex"
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: '30px',
          }}
        >
          <PendingVideo ref={pendingVideoRef} />
        </Box>

        <Box
          flex={1}
          sx={{
            padding: '30px',
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
            sx={{
              height: '100%'
          }}
          >
            <DeviceAudioInDropdown
              handleChange={handleChangeAudioInput}
            />

            <SoundMeter stream={webrtcStream} audioInputDevice={selectedAudioInputDevice} />

            <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
              <InputLabel id="settings-audio-output">Speaker</InputLabel>
              <Select
                labelId="settings-audio-output"
                id="settings-audio-output"
                value={selectedAudioOutputDevice}
                label="Speaker"
                onChange={handleChangeAudioOutput}
              >
                {audioOutputDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ mt: 1, mb: 1, width: '100%' }} size="small">
              <InputLabel id="settings-video-input">Camera</InputLabel>
              <Select
                labelId="settings-video-input"
                id="settings-video-input"
                value={selectedVideoInputDevice}
                label="Camera"
                onChange={handleChangeVideoInput}
              >
                <MenuItem key="off" value="off">Off</MenuItem>
                {videoInputDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button variant="contained" onClick={doCall}>Connect</Button>
          </Stack>

        </Box>

      </Box>
    </Box>
  );
};

export default PendingConnectionBox;
