import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import LeftSidebar from "./LeftSidebar";
import InitialConnectDialog from "./Dialogs/InitialConnectDialog";
import VideoContainer from "./Video/VideoContainer";
import PendingConnectionBox from "./Video/PendingConnectionBox";
import {useVideoCall} from "../contexts/VideoCallContext";
import {Paper} from "@mui/material";

const CloseButton = styled(Button)({
  backgroundColor: '#EFF1F3',
  border: 'none',
  height: "40px",
  width: "40px",
  minWidth: "0",
  borderRadius: "30%",
  '&:hover': {
    backgroundColor: '#D0D6DC',
    border: 'none',
  },
});

const MainContainer = (props) => {
  const {
    callState,
    videoCallToken,
  } = useVideoCall();

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="row"
    >
      <Box
        width="150px"
      >
        <LeftSidebar />
      </Box>

      <Divider orientation="vertical" variant="middle" flexItem />

      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        flex={1}
      >
        <Box
          height="60px"
          // style={{ color: "white", background: "blue" }}
          sx={{
            paddingLeft: "30px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{
              height: "100%",
            }}
          >
            <CloseButton size="small" variant="outlined">
              <CloseIcon />
            </CloseButton>


            <Box sx={{
              fontSize: "25px",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "800",
            }}>
              Video Conference
            </Box>
            <div>Guest Token: <span>{videoCallToken}</span></div>
          </Stack>

        </Box>

        <Box>
          <Divider variant="middle" />
        </Box>

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          sx={{
            position: 'relative',
          }}
        >
          <Paper sx={{ display: callState.inCall ? 'inline' : 'none' }}>
            <VideoContainer />
          </Paper>

          {!callState.inCall && (
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, height: '80vh' }}>
              <PendingConnectionBox />
            </Box>
          )}
        </Box>

        {/*<Box height="100px" style={{ color: "white", background: "blue" }}>*/}
        {/*  <Button variant="contained" onClick={doCall}>Connect</Button>*/}
        {/*  <br/>*/}

        {/*  <br />*/}

        {/*  {JSON.stringify(audioDevices)}*/}
        {/*</Box>*/}
      </Box>
    </Box>
  )
}

export default MainContainer;
