import React, {useEffect, useRef} from 'react';
import {styled} from "@mui/material/styles";

const StyledMeter = styled('meter')({
  width: '50%',
  minWidth: '300px',
});

const SoundMeter = ({stream, audioInputDevice}) => {
  const audioInputMeterRef = useRef(null);

  useEffect(() => {
    try {
      if(audioInputDevice === 'off' || !stream || !stream.getAudioTracks().length) {
        audioInputMeterRef.current.value = 0;
        return;
      }
    } catch (err) {
      console.error(err)

      audioInputMeterRef.current.value = 0;
      return;
    }


    // Create and configure the audio pipeline
    const audioContext = new AudioContext();
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 512;
    analyzer.smoothingTimeConstant = 0.1;
    const sourceNode = audioContext.createMediaStreamSource(stream);
    sourceNode.connect(analyzer);

    // Analyze the sound
    const intId = setInterval(() => {
      // Compute the max volume level (-Infinity...0)
      const fftBins = new Float32Array(analyzer.frequencyBinCount); // Number of values manipulated for each sample
      analyzer.getFloatFrequencyData(fftBins);
      // audioPeakDB varies from -Infinity up to 0
      const audioPeakDB = Math.max(...fftBins);

      // Compute a wave (0...)
      const frequencyRangeData = new Uint8Array(analyzer.frequencyBinCount);
      analyzer.getByteFrequencyData(frequencyRangeData);
      const sum = frequencyRangeData.reduce((p, c) => p + c, 0);
      // audioMeter varies from 0 to 10
      const audioMeter = Math.sqrt(sum / frequencyRangeData.length);

      audioInputMeterRef.current.value = audioMeter;
    }, 250);

    return () => {
      if(intId)
        clearInterval(intId)
    };
  }, [stream]);


  return <StyledMeter
    ref={audioInputMeterRef}
    min="0"
    max="10"
    high="7"
    value=""
  />;
};

export default SoundMeter;
