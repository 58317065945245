import { atom } from 'jotai'
import axios from "axios";

const defaultMeetingState = {
  guestToken: null,
  guestTokenValid: false,
  guestTokenValidating: false,
  guestTokenErrorMessage: null,
};

const meetingAtom = atom(defaultMeetingState)

export const guestTokenAtom = atom(
  (get) => {
    const meeting = get(meetingAtom);
    return meeting.guestToken;
  },
  async (get, set, guestToken) => {
    const meeting = get(meetingAtom);
    set(meetingAtom, {
      ...meeting,
      guestTokenValidating: true,
    })

    // Verify the token
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_YTEL_API_BASE_URL}/api/v4/external/guest/validate/${guestToken}`,
      };

      const tokenVerifyResponse = await axios.request(config);
      if(!tokenVerifyResponse || !tokenVerifyResponse?.data?.status) {
        const message = tokenVerifyResponse?.data?.error?.[0]?.message || 'Cannot check the validity of the token. Please try again later.';
        set(meetingAtom, {
          guestToken,
          guestTokenValid: false,
          guestTokenValidating: false,
          guestTokenErrorMessage: message,
        });

      } else if(tokenVerifyResponse.data.status && !tokenVerifyResponse?.data?.payload[0].canLogin ) {
        set(meetingAtom, {
          guestToken,
          guestTokenValid: false,
          guestTokenValidating: false,
          guestTokenErrorMessage: tokenVerifyResponse.data.payload[0].errorMsg,
        });

      } else {
        set(meetingAtom, {
          guestToken,
          guestTokenValid: true,
          guestTokenValidating: false,
          guestTokenErrorMessage: null,
        });
      }
    } catch (e) {
      console.error(e);

      set(meetingAtom, {
        guestToken,
        guestTokenValid: false,
        guestTokenValidating: false,
        guestTokenErrorMessage: 'Please check your internet connection and try again.',
      });
    }
  }
)

export const guestTokenValidAtom = atom((get) => get(meetingAtom).guestTokenValid);
export const guestTokenValidatingAtom = atom((get) => get(meetingAtom).guestTokenValidating);
export const guestTokenErrorMessageAtom = atom((get) => get(meetingAtom).guestTokenErrorMessage);
