import React from 'react';
import {styled} from "@mui/material/styles";

const StyledVideo = styled('video')({
  borderRadius: '30px',
  objectFit: 'cover',
  maxHeight: '450px',
  border: '1px solid #D0D6DC',
});

const PendingVideo = ({
  id = 'pending-view',
  height = '',
  width = '100%',
}, ref) => {
  return (
    <StyledVideo
      id={id}
      height={height}
      width={width}
      autoPlay
      vol
      ref={ref}
    ></StyledVideo>
  );
};

export default React.forwardRef(PendingVideo);
