import React from 'react';
import {styled} from "@mui/material/styles";

const StyledVideo = styled('video')({
  borderRadius: '30%',
  objectFit: 'cover',
});

const LocalVideo = ({
  id = 'videoDivLocal',
  height = '250',
  width = '250',
}) => {
  return (
    <StyledVideo
      id={id}
      height={height}
      width={width}
      autoPlay
      muted
    ></StyledVideo>
  );
};

export default LocalVideo;
