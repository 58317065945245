import React, {useState, useContext, useEffect, createContext, useRef} from 'react'
import * as JsSIP from "jssip";
import {
  CALL_DIRECTION_INCOMING,
  CALL_DIRECTION_OUTGOING,
  CALL_STATUS_ACTIVE,
  CALL_STATUS_IDLE,
  CALL_STATUS_STARTING,
  CALL_STATUS_STOPPING,
} from "../constants/call";
import {useDevices} from "./DevicesContext";
import {useAtom} from "jotai";
import _debounce from 'lodash/debounce';
import {errorMessageAtom} from "../atoms/errorMessageAtom";

const ylogger = console;

const VideoCallContext = createContext({});

export const useVideoCall = () => useContext(VideoCallContext);

function createRemoteAudioElement() {
  const id = 'sip-provider-audio'
  let el = window.document.getElementById(id)

  if (el) {
    return el
  }

  el = window.document.createElement('audio')
  el.id = id
  el.autoplay = true
  el.volume = 1

  window.document.body.appendChild(el)

  return el
}

const iceServers = [
  {
    urls: [
      'stun:stun.l.google.com:19302',
      // 'stun:stun1.l.google.com:19302',
      // 'stun:stun2.l.google.com:19302',
      // 'stun:stun3.l.google.com:19302',
      // 'stun:stun4.l.google.com:19302',
      // 'stun:stun.freeswitch.org:3478'
    ]
  },
];

const iceServers2 = [
  {url:'stun:stun01.sipphone.com'},
  {url:'stun:stun.ekiga.net'},
  {url:'stun:stun.fwdnet.net'},
  {url:'stun:stun.ideasip.com'},
  {url:'stun:stun.iptel.org'},
  {url:'stun:stun.rixtelecom.se'},
  {url:'stun:stun.schlund.de'},
  {url:'stun:stun.l.google.com:19302'},
  {url:'stun:stun1.l.google.com:19302'},
  {url:'stun:stun2.l.google.com:19302'},
  {url:'stun:stun3.l.google.com:19302'},
  {url:'stun:stun4.l.google.com:19302'},
  {url:'stun:stunserver.org'},
  {url:'stun:stun.softjoys.com'},
  {url:'stun:stun.voiparound.com'},
  {url:'stun:stun.voipbuster.com'},
  {url:'stun:stun.voipstunt.com'},
  {url:'stun:stun.voxgratia.org'},
  {url:'stun:stun.xten.com'},
  {
    url: 'turn:numb.viagenie.ca',
    credential: 'muazkh',
    username: 'webrtc@live.com'
  },
  {
    url: 'turn:192.158.29.39:3478?transport=udp',
    credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
    username: '28224511:1379330808'
  },
  {
    url: 'turn:192.158.29.39:3478?transport=tcp',
    credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
    username: '28224511:1379330808'
  }
];

export const VideoCallContextProvider = (props) => {
  const [videoCallToken, setVideoCallToken] = useState(props.roomToken);
  const [callState, setCallState] = useState({
    callStatus: CALL_STATUS_IDLE,
    // callDirection: null,
    // callCounterpart: null,
    startingCall: false,
    inCall: false,
    callIsOnHold: false,
    callMicrophoneIsMuted: false,
    callVideoIsMuted: false,
    callStartTime: null
  });
  const [callError, setCallError] = useState(null);
  const [, setErrorMessage] = useAtom(errorMessageAtom);
  const {
    selectedAudioInputDevice,
    selectedAudioOutputDevice,
    selectedVideoInputDevice,
    getConstraints,
  } = useDevices();

  const coolPhoneRef = useRef();
  const rtcSessionRef = useRef();
  const remoteAudioRef = useRef(createRemoteAudioElement());
  const peerConnCalleeRef = useRef();
  const getRemoteAudioElement = () => remoteAudioRef.current;

  const iceServerToReadyRef = useRef(false);

  const videoIceThrottle = () =>
    _debounce(() => {
      iceServerToReadyRef.current = true;
    }, 5000, { maxWait: 5000 });

  useEffect(() => {
    if(!videoCallToken) {
      return;
    }

    const doVideo = true;

    let socket = new JsSIP.WebSocketInterface(process.env.REACT_APP_WEBSOCKET_URL);
    let configuration = {
      sockets  : [ socket ],
      uri      : `${videoCallToken}@ytel.com`,
      register : false
    };
    coolPhoneRef.current = new JsSIP.UA(configuration);
    let localStream = new MediaStream();
    let remoteVideo = document.getElementById("videoDivRemote");
    let localVideo = document.getElementById("videoDivLocal");

    const mediaSource = new MediaSource();
    JsSIP.debug.enable('JsSIP:*');

    // coolPhoneRef.current.on('connected', function(e){ });
    // coolPhoneRef.current.on('disconnected', function(e){ });
    // coolPhoneRef.current.on('peerconnection', (pc) => {
    //   console.log('peerconnection - outside', pc);
    //   peerConnCalleeRef.current = pc.peerconnection;
    // });

    let curSession = null;
    coolPhoneRef.current.on('newRTCSession', (data) => {

      console.log("newRtc session ");
      let dataSession = data.session;
      let originator = data.originator;
      rtcSessionRef.current = dataSession;

      curSession = dataSession;

      // const t = curSession.connection.getLocalStreams();
      // console.log("newRtc session 2", curSession, t);

      curSession.on('muted', (data) => {
        console.log(data)
      });

      curSession.on('unmuted', (data) => {
        console.log(data)
      });

      // localVideo.srcObject = (curSession.connection.getLocalStreams()[0]);

      /* For outbound call - to add stream to audio tag */
      if (originator === 'local') {
        dataSession.connection.addEventListener('track', (event) => {
          console.log('connection.addstream', event, event.streams[0])
          const stream = event.streams[0];

          if (stream) {

            console.log('connection.addstream', event, stream);
            remoteAudioRef.current.srcObject = stream;
            remoteAudioRef.current.play();

            // Video call
            const selfView = document.getElementById('videoDivLocal');
            const remoteView = document.getElementById('videoDivRemote');
            remoteView.srcObject = (stream);
            remoteView.play();
            const localStream = rtcSessionRef.current.connection.getLocalStreams();
            selfView.srcObject = (localStream[0]);
            console.log('localstream', localStream[0].getTracks())
            console.log('remotestream', stream)
          }
        });
      }

      dataSession.on('peerconnection', function(pc) {
        console.log('peerconnection', pc);

        // Test
        pc.addEventListener("track", (event) => {
          console.log('peerconnection.addstream', event, event.streams[0]);

          const stream = event.streams[0];
          if(stream) {
            stream.addEventListener("removetrack", (event) => {
              console.log('peerconnection', event);
            });
            console.log('peerconnection.addstream: set remoteAudioRef.current.srcObject', stream);
            remoteAudioRef.current.srcObject = stream;
            remoteAudioRef.current.play();
            // remoteVideo.srcObject = stream;

            // Video call
            const selfView = document.getElementById('videoDivLocal');
            const remoteView = document.getElementById('videoDivRemote');
            remoteView.srcObject = stream;
            remoteView.play();
            selfView.srcObject = (rtcSessionRef.current.connection.getLocalStreams()[0]);
          }
        });

        remoteAudioRef.current.srcObject = pc.peerconnection.getReceivers()[0];
        // pc.peerconnection.addEventListener('addstream', (event) => {
        //   console.log('connection.addstream', event);
        //   const stream = event.stream;
        //   if (stream) {
        //     console.log('connection.addstream: set remoteAudioRef.current.srcObject', stream);
        //     remoteAudioRef.current.srcObject = stream;
        //     remoteAudioRef.current.play();
        //       // remoteVideo.srcObject = stream;
        //
        //     // Video call
        //     const selfView = document.getElementById('videoDivLocal');
        //     const remoteView = document.getElementById('videoDivRemote');
        //     remoteView.srcObject = stream;
        //     remoteView.play();
        //     selfView.srcObject = (rtcSessionRef.current.connection.getLocalStreams()[0]);
        //   }
        // });
        //add_stream();
      });


      dataSession.on('icecandidate', function(event, ready) {
        ylogger.debug('jssip.event.rtc.icecandidate', event.candidate)

        if(iceServerToReadyRef.current) {
          iceServerToReadyRef.current = false;

          if (event.candidate) {
            ylogger.debug('jssip.event.rtc.icecandidate', 'ICE type: '+event.candidate.type+' candidate:', event.candidate);
            if (event.candidate.type === "srflx") {
              if (event.candidate.relatedAddress !== null && event.candidate.relatedPort !== null) {
                event.ready();
                ylogger.debug('jssip.event.rtc.icecandidate', 'set ready');
              } else {
                ylogger.debug('jssip.event.rtc.icecandidate', 'data set '+ event.candidate.relatedAddress + ' port '+ event.candidate.relatedPort);
              }
            }
          } else {
            ylogger.debug('jssip.event.rtc.icecandidate', 'Done Gathering ice:');
          }
        } else {
          videoIceThrottle();
        }

        // if (event.candidate) {
        //   console.log('ICE type: '+event.candidate.type+' candidate:', event.candidate);
        //   // if (event.candidate.type === "srflx") {
        //     if (event.candidate.relatedAddress !== null && event.candidate.relatedPort !== null) {
        //       event.ready();
        //       console.log('set ready');
        //     } else {
        //       console.log('data set '+ event.candidate.relatedAddress + ' port '+ event.candidate.relatedPort);
        //     }
        //   // }
        // } else {
        //   console.log('Done Gathering ice:');
        // }

        // if (event.candidate) {
        //   console.log('ICE type: '+event.candidate.type+' candidate:', event.candidate);
        //   //if (event.candidate.type === "srflx") {
        //   //if (event.candidate.relatedAddress !== null && event.candidate.relatedPort !== null) {
        //   //event.ready();
        //   //console.log('set ready');
        //   //} else {
        //   //console.log('data set '+ event.candidate.relatedAddress + ' port '+ event.candidate.relatedPort);
        //   //}
        //   //}
        // } else {
        //   console.log('Done Gathering ice:');
        // }
      });

      if(originator === 'remote'){
        console.log('stream incoming  -------->');
        dataSession.on('connecting', function() {
          console.log('CONNECT');
        });
        let completeSession = function(event){
          console.log('ended', event);

          curSession = null;
          setCallState({...callState, inCall: false,})
        };

        dataSession.on('ended', completeSession);
        dataSession.on('failed', completeSession);
        dataSession.on('accepted',function(e) {
          console.log('accepted')
        });
        dataSession.on('confirmed',function(e){
          console.log('CONFIRM STREAM');
        });

        let options = {
          'mediaConstraints' : { 'audio': true, 'video': doVideo },
          'pcConfig': {
            'rtcpMuxPolicy': 'require',
            'iceServers': [ ]
          },
        };
        console.log('Incoming Call to answer');
        // autoAnswer
        dataSession.answer(options);
      }

    });

    coolPhoneRef.current.on('newMessage', function(e){alert("new message"); });
    coolPhoneRef.current.on('registered', function(e){ });
    coolPhoneRef.current.on('unregistered', function(e){alert("unregistered"); });
    coolPhoneRef.current.on('registrationFailed', function(e){alert("reg Failed"); });

    coolPhoneRef.current.start();

    // let remoteAudio = createRemoteAudioElement();

    // let session;
    // function add_stream() {
    //   curSession.connection.addEventListener('addstream',function(e) {
    //     remoteAudio.srcObject = (e.stream);
    //     if (doVideo) {
    //       remoteVideo.srcObject = (e.stream);
    //       selfVideo.srcObject = (curSession.connection.getLocalStreams()[0]);
    //     }
    //   })
    // }

    return () => {
    };
  }, [videoCallToken]);

  const doCall = () => {
    const sessionTimersExpires = 180;
    const anonymous = false;

    // Register callbacks to desired call events
    let eventHandlers = {
      'progress': function(e) {
        console.log('call is in progress', e);

        setCallState({
          ...callState,
          callStatus: CALL_STATUS_STARTING,
          inCall: false,
        });
      },
      'failed': function(e) {
        console.log('call failed with cause: ', e);

        setErrorMessage(e.cause);

        setCallState({
          ...callState,
          callStatus: CALL_STATUS_IDLE,
          inCall: false,
        });
      },
      'ended': function(e) {
        console.log('call ended with cause: ', e);

        setCallState({
          ...callState,
          callStatus: CALL_STATUS_IDLE,
          inCall: false,
        });
      },
      'confirmed': function(e) {
        console.log('call confirmed', e);

        setCallState({
          ...callState,
          callStatus: CALL_STATUS_ACTIVE,
          inCall: true,
        });
      }
    };

    const constraints = getConstraints();

    let options = {
      'eventHandlers'    : eventHandlers,
      'mediaConstraints' : constraints,
      // rtcOfferConstraints: { iceRestart: false },
      'pcConfig': {
        iceServers,
      },
      sessionTimersExpires,
      anonymous
    };

    console.log("Send Call", options);

    coolPhoneRef.current.call(`${videoCallToken}@ytel.com`, options);
    // let output = '';
    // for (property in thissession.connection) {
    //   output += property + ': ';
    // }
    // console.log("sdf "+output);

    //thissession.onicecandidate = function (event) {
    //console.log('ICE candidate:', event.candidate);
    //};

    console.log("Send Call Returned");
  };

  const endCall = () => {
    if (rtcSessionRef.current) {
      rtcSessionRef.current.terminate();
    }
  }

  // const mute = () => {
  //   if (rtcSessionRef.current) {
  //     rtcSessionRef.current.mute({ audio: true, video: callState.callVideoIsMuted })
  //     setCallState((prevState) => ({ ...prevState, callMicrophoneIsMuted: true }))
  //   } else {
  //     console.debug('Warning:', 'You are attempting to mute, but there is no numberListReady call.')
  //   }
  // }
  //
  // const unmute = () => {
  //   if (rtcSessionRef.current) {
  //     rtcSessionRef.current.unmute({ audio: false, video: callState.callVideoIsMuted })
  //     setCallState((prevState) => ({ ...prevState, callMicrophoneIsMuted: false }))
  //   } else {
  //     console.debug('Warning:', 'You are attempting to unmute, but there is no numberListReady call.')
  //   }
  // }
  //
  // const toggleMute = () => {
  //   if (callState.callMicrophoneIsMuted) {
  //     unmute();
  //   } else {
  //     mute();
  //   }
  // };

  const muteAudio = () => {
    if (callState.callStatus === 'callStatus/ACTIVE' && rtcSessionRef.current) {
      rtcSessionRef.current.mute({ audio: true, video: false })
      setCallState((prevState) => ({ ...prevState, callMicrophoneIsMuted: true }))
    } else {
      ylogger.debug('Warning:', 'You are attempting to mute, but there is no numberListReady call.')
    }
  }

  const unmuteAudio = () => {
    if (callState.callStatus === 'callStatus/ACTIVE' && rtcSessionRef.current) {
      ylogger.debug('unmuteAudio', { audio: { echoCancellation: true }, video: !callState.callVideoIsMuted })

      rtcSessionRef.current.unmute({ audio: { echoCancellation: true }, video: false })
      setCallState((prevState) => ({ ...prevState, callMicrophoneIsMuted: false }))
    } else {
      ylogger.debug('Warning:', 'You are attempting to unmute, but there is no numberListReady call.')
    }
  }

  const toggleMuteAudio = () => {
    if (callState.callMicrophoneIsMuted) {
      unmuteAudio()
    } else {
      muteAudio()
    }
  }



  const muteVideo = () => {
    if (callState.callStatus === 'callStatus/ACTIVE' && rtcSessionRef.current) {
      let localVideo = document.getElementById("videoDivLocal");
      localVideo.srcObject.getTracks().forEach(t => t.enabled = false);

      rtcSessionRef.current.mute({ audio: false, video: true });

      setCallState((prevState) => ({ ...prevState, callVideoIsMuted: true }))
    } else {
      ylogger.debug('Warning:', 'You are attempting to mute, but there is no numberListReady call.')
    }
  }

  const unmuteVideo = () => {
    if (callState.callStatus === 'callStatus/ACTIVE' && rtcSessionRef.current) {
      let localVideo = document.getElementById("videoDivLocal");
      localVideo.srcObject.getTracks().forEach(t => t.enabled = true);

      rtcSessionRef.current.unmute({ audio: false, video: true });

      setCallState((prevState) => ({ ...prevState, callVideoIsMuted: false }))
    } else {
      ylogger.debug('Warning:', 'You are attempting to unmute, but there is no numberListReady call.')
    }
  }

  const toggleMuteVideo = () => {
    if (callState.callVideoIsMuted) {
      unmuteVideo()
    } else {
      muteVideo()
    }
  }

  const changedInputDevices = () => {
    const constraints = getConstraints();
    console.log("changedInputDevices", constraints);

    navigator
      .mediaDevices.getUserMedia(constraints)
      .then(function(stream){
       // localVideo.srcObject = stream;
       const selfView = document.getElementById('videoDivLocal');
       selfView.srcObject = stream;
       stream.getVideoTracks().forEach(function(track) {
         var sender = rtcSessionRef.current.connection.getSenders().find(function(s) {
           return s.track.kind == track.kind;
         });
         sender.replaceTrack(track);
       });

       // rtcSessionRef.current.renegotiate({
       //   rtcOfferConstraints: constraints,
       // }, (done) => {
       //   console.log("renegotiate done", done);
       // });
      })
      .catch(function(e) {
       console.log("error", e);
});


  }


  return (
    <VideoCallContext.Provider value={{
      videoCallToken,
      callState,
      doCall,
      endCall,
      toggleMute: toggleMuteAudio,
      toggleLocalVideoMute: toggleMuteVideo,
      getRemoteAudioElement,
      changedInputDevices,
    }}>
      {props.children}
    </VideoCallContext.Provider>
  );
}
