import React from 'react';
import Box from "@mui/material/Box";
import MainVideo from "./MainVideo";
import LocalVideo from "./LocalVideo";
import VideoTimer from "./VideoTimer";
import Stack from "@mui/material/Stack";
import MicOffRoundedIcon from "@mui/icons-material/MicOffRounded";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
import CallEndRoundedIcon from "@mui/icons-material/CallEndRounded";
import VideocamOffRoundedIcon from "@mui/icons-material/VideocamOffRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import SettingDialog from "../Dialogs/SettingDialog";
import {useVideoCall} from "../../contexts/VideoCallContext";
import {styled} from "@mui/material/styles";
import {Fab} from "@mui/material";

const ControlFabButton = styled(Fab, {
  shouldForwardProp: (prop) =>
    !["red"].includes(prop)
})(({red}) => ({
  backdropFilter: 'blur(6px)',
  backgroundColor: !red ? 'rgba(100,100,100, 0.4)' : '#EA6861',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: !red ? 'rgba(100,100,100, 1)' : '#ee5c4b',
  },
}));

const ControlFabHangupButton = styled(Fab)({
  height: '80px',
  width: '80px',
  backgroundColor: '#EA6861',
  borderRadius: '40%',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#ee5c4b',
  }
});

const VideoContainer = () => {
  const {
    callState,
    doCall,
    endCall,
    toggleMute,
    toggleLocalVideoMute,
  } = useVideoCall();

  return (
    <Box sx={{
      position: 'relative',
      padding: "30px",
      // backgroundColor: "red",
    }}>
      <Box sx={{
        height: '80vh',
      }}>
        <MainVideo />
      </Box>

      <Box sx={{
        position: 'absolute',
        right: '100px',
        bottom: '100px',
        border: '6px solid white',
        borderRadius: '30%',
        height: '262px',
        width: '262px',
      }}>
        <LocalVideo />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '70px',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <VideoTimer />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: '30px',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{
            height: "100%",
          }}
        >
          <ControlFabButton
            aria-label="mute & unmute"
            onClick={toggleMute}
            red={callState.callMicrophoneIsMuted}
          >
            {callState.callMicrophoneIsMuted ? <MicOffRoundedIcon /> : <MicRoundedIcon />}
          </ControlFabButton>

          <ControlFabHangupButton
            color="primary"
            aria-label="end call"
            onClick={endCall}
          >
            <CallEndRoundedIcon
              sx={{
                fontSize: "40px",
              }}
            />
          </ControlFabHangupButton>

          <ControlFabButton
            color="primary"
            aria-label="camera"
            onClick={toggleLocalVideoMute}
            red={callState.callVideoIsMuted}
          >
            {callState.callVideoIsMuted ? <VideocamOffRoundedIcon /> : <VideocamRoundedIcon />}
          </ControlFabButton>

          <SettingDialog />
        </Stack>
      </Box>
    </Box>
  );
};

export default VideoContainer;
